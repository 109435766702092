import moment from 'moment'

export const shouldDisableRow = (
  status: string | null,
  sub_status: string | null,
): boolean => {
  const warmLeadSubStatuses = [
    'For Reservation',
    'Application Approved',
    'Approved but Looking for A Unit',
    'Approved but Considering Paying Cash',
    'Applied but On Hold',
    'Approved but On Hold',
  ]

  const hotLeadSubStatuses = [
    'With Reservation',
    'Released',
    'With Reservation but Looking for A Unit',
  ]

  if (
    status === 'Warm Lead' &&
    sub_status &&
    warmLeadSubStatuses.includes(sub_status)
  ) {
    return true
  }

  if (
    status === 'Hot Lead' &&
    sub_status &&
    hotLeadSubStatuses.includes(sub_status)
  ) {
    return true
  }

  return false
}

export const renderARStatus = row => {
  const ar = row.ar
  let status = ''

  if (!ar) return 'N/A'

  if (ar.collected_di_amount !== null && ar.collected_proceeds_amount !== 0) {
    status = 'Fully Collected'
  }
  if (
    parseFloat(ar.collected_proceeds_amount) !== 0 &&
    ar.collected_di_amount == null
  ) {
    status = 'Collected Proceeds'
  }
  if (
    ar.fin_partner_transmittal === true &&
    parseFloat(ar.collected_proceeds_amount) === 0
  ) {
    status = 'Review'
  }
  if (ar.cnc_trasmittal === true && ar.fin_partner_transmittal === false) {
    status = 'For Transmittal to Financing Partner'
  }
  if (ar.for_ORCR_processing === true && ar.cnc_trasmittal === false) {
    status = 'For Transmittal to C&C'
  }
  if (ar.transmitted_lto_liaison === true && ar.for_ORCR_processing === false) {
    status = 'For Process OR/CR'
  }

  if (ar.transmitted_lto_liaison === false) {
    if (row.vehicle_sale.amount_balance > 0) {
      status = 'Pending'
    } else {
      status = 'Collected DP'
    }
  }

  return status
}

export const pesoFormatter = new Intl.NumberFormat('en-PH', {
  style: 'currency',
  currency: 'PHP',
})

export const renderDate = (date, time = false) => {
  if (date !== null) {
    if (time) {
      return moment(date).format('MMMM DD, YYYY: h:mmA')
    } else {
      return moment(date).format('dddd, MMMM Do YYYY')
    }
  } else {
    return 'N/A'
  }
}

export const getAppraisalStatus = (
  status: string | null,
  role: string[],
  initialAppraisedValue: number | null,
): string[] => {
  const defaultCollection = [
    'Under Consideration',
    'Under Negotiation',
    'Not Recommended',
  ]

  const collections: Record<string, string[]> = {
    Canvassing: defaultCollection,
    Inspected: ['For Approval'],
    'For Approval': ['Approved', 'Rejected'],
    Approved: ['For Payment'],
    'For Payment': ['P.O Issued'],
    'P.O Issued': ['Acquired'],
    'Under Consideration': ['Under Negotiation', 'Not Recommended'],
    'Under Negotiation': ['Not Recommended', 'Under Consideration'],
  }

  if (!status || !collections[status]) return []

  let allowedStatuses = collections[status]

  const safeInitialAppraisedValue = initialAppraisedValue ?? 0

  if (role.includes('super_admin') || role.includes('manager')) {
    if (status === 'For Approval' && safeInitialAppraisedValue > 0) {
      allowedStatuses = ['Approved', 'Declined']
    } else if (status === 'For Approval') {
      allowedStatuses = []
    }
  } else {
    if (status === 'For Approval') {
      allowedStatuses = []
    }
  }

  return allowedStatuses
}

export const formatDate = (
  date?: string,
  format: string = 'MMM. D, YYYY',
): string => {
  if (!date || !moment(date, moment.ISO_8601, true).isValid()) {
    return ''
  }
  return moment(date).format(format)
}
