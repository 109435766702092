/**
 *
 * AppraisalStatusUpdate
 *
 */
import * as React from 'react'
// React bootstrap
import { Modal, Button } from 'react-bootstrap'
// Externals
import { useForm, Controller } from 'react-hook-form'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-bootstrap-tagsinput/dist/index.css'
// Utils
import { formatDate, getAppraisalStatus } from 'utils/helper'
import { useEffect } from 'react'

interface Props {
  visible: boolean
  handleClose: () => void
  appraisal: any
  handleSubmit: (data: any) => void
  role: string
  suppliers: any
}

interface dateProps {
  date_scheduled_inspection?: Date
  date_actual_inspection?: Date
  date_approved?: Date
  date_acquired?: Date
  date_submitted_payment_request_admin?: Date
  date?: Date
}

const dateFields: { [key: string]: keyof dateProps } = {
  'For Inspection': 'date_scheduled_inspection',
  Inspected: 'date_actual_inspection',
  Approved: 'date_approved',
  Acquired: 'date_acquired',
  'For Payment': 'date_submitted_payment_request_admin',
  'P.O Issued': 'date',
}

const getDateLabel = (status: string): string => {
  const statusLabel: Record<string, string> = {
    'For Inspection': 'Date of schedule inspection',
    Inspected: 'Date of actual inspection',
    Approved: 'Date Approved',
    Acquired: 'Date Acquired',
    'For Payment': 'Date submitted to admin for payment request',
    'P.O Issued': 'Date',
  }
  return statusLabel[status] || ''
}

export function AppraisalStatusUpdate(props: Props) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm()

  const watchStatus = watch('status')

  useEffect(() => {
    if (watchStatus && dateFields[watchStatus]) {
      const currentField = dateFields[watchStatus]
      const currentValue = watch(currentField)
      if (!currentValue) {
        setValue(currentField, new Date())
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchStatus])

  const onSubmit = (data: any) => {
    data.id = props.appraisal.id

    if (data.status && dateFields[data.status]) {
      const dateKey = dateFields[data.status] as
        | 'date_scheduled_inspection'
        | 'date_actual_inspection'
        | 'date_approved'
        | 'date_acquired'
        | 'date_submitted_payment_request_admin'
        | 'date'

      const dateValue = data[dateKey]
      const dateString =
        dateValue instanceof Date ? dateValue.toISOString() : dateValue

      if (typeof dateString === 'string') {
        data[dateKey] = formatDate(dateString, 'YYYY-MM-DD')
      }
    }

    if (data.status === 'P.O Issued') {
      const min = 10000
      const max = 99999
      const unique_number = Math.floor(Math.random() * (max - min + 1)) + min
      const selectedSupplier = props?.suppliers?.find(
        (supp: any) => supp?.id === parseInt(data.supplier),
      )

      data.supplier = selectedSupplier?.name
      data.supplier_id = selectedSupplier?.id
      data.po_number = unique_number
    }

    reset()
    props.handleSubmit(data)
  }
  const availableStatuses = getAppraisalStatus(
    props.appraisal?.status ?? null,
    Array.isArray(props.role) ? props.role : [props.role],
    props.appraisal?.initial_appraised_value ?? 0,
  )

  return (
    <Modal show={props.visible} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>Appraisal status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="status">Status</label>
                <select
                  id="status"
                  className={`form-control form-control-lg ${
                    errors?.status ? 'is-invalid' : ''
                  }`}
                  {...register('status', { required: true })}
                >
                  <option value="" />
                  {availableStatuses.map(status => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>
              {watchStatus && dateFields[watchStatus] && (
                <div className="form-group">
                  <label className={`form-label small`} htmlFor="date">
                    {getDateLabel(watchStatus)}
                  </label>
                  <Controller
                    control={control}
                    name={dateFields[watchStatus]}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <div style={{ position: 'relative', width: '100%' }}>
                        <DatePicker
                          id="date"
                          selected={value}
                          onChange={(date: Date | null) => onChange(date)}
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          className={`form-control ${
                            errors[dateFields[watchStatus]] ? 'is-invalid' : ''
                          }`}
                          autoComplete="off"
                          disabled={watchStatus === 'P.O Issued'}
                        />
                      </div>
                    )}
                  />
                </div>
              )}
              {watchStatus === 'P.O Issued' && (
                <>
                  <div className="form-group">
                    <label htmlFor="supplier">Supplier</label>
                    <select
                      id="supplier"
                      className={`form-control form-control-lg ${
                        errors?.supplier ? 'is-invalid' : ''
                      }`}
                      {...register('supplier', { required: true })}
                    >
                      <option value="" />
                      {props?.suppliers?.map(supplier => (
                        <option key={supplier?.id} value={supplier?.id}>
                          {supplier?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="address">Address</label>
                    <input
                      id="address"
                      className={`form-control form-control-lg ${
                        errors?.address ? 'is-invalid' : ''
                      }`}
                      {...register('address', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="payment_terms">Terms of payment</label>
                    <input
                      id="payment_terms"
                      className={`form-control form-control-lg ${
                        errors?.payment_terms ? 'is-invalid' : ''
                      }`}
                      {...register('payment_terms', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="quantity">Quantity</label>
                    <input
                      id="quantity"
                      type="number"
                      className={`form-control form-control-lg ${
                        errors?.quantity ? 'is-invalid' : ''
                      }`}
                      {...register('quantity', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <input
                      id="description"
                      className={`form-control form-control-lg ${
                        errors?.description ? 'is-invalid' : ''
                      }`}
                      {...register('description', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="unit_price">Unit price</label>
                    <input
                      id="unit_price"
                      type="number"
                      className={`form-control form-control-lg ${
                        errors?.unit_price ? 'is-invalid' : ''
                      }`}
                      {...register('unit_price', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="prepared_by">Prepared by:</label>
                    <input
                      id="prepared_by"
                      className={`form-control form-control-lg ${
                        errors?.prepared_by ? 'is-invalid' : ''
                      }`}
                      {...register('prepared_by', { required: true })}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="approved_by">Approved by:</label>
                    <input
                      id="approved_by"
                      className={`form-control form-control-lg ${
                        errors?.approved_by ? 'is-invalid' : ''
                      }`}
                      {...register('approved_by', { required: true })}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="col-lg-12">
              <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1">Remarks</label>
                <textarea
                  className="form-control form-control-lg"
                  id="exampleFormControlTextarea1"
                  rows={3}
                  {...register('remarks', { required: false })}
                ></textarea>
              </div>
            </div>
          </div>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary">
            Update
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  )
}
