/**
 *
 * Appraisal
 *
 */
import * as React from 'react'
// React bootstrap
import { Modal, Button } from 'react-bootstrap'
// External
import { useForm, Controller } from 'react-hook-form'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import Select from 'react-select'
import { AiFillCloseCircle } from 'react-icons/ai'
import { BsChevronRight } from 'react-icons/bs'
import DatePicker from 'react-datepicker'

const thumbsContainer: any = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: 16,
}

const thumb: any = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner: any = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img: any = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const styles = {
  control: (base, state) => ({
    ...base,
    borderColor: 'transparent', // Always transparent
    boxShadow: 'none', // No focus outline or shadow
    minHeight: '48px',
    height: '48px',
    '&:hover': {
      borderColor: 'transparent', // No hover effect
    },
    '&:focus': {
      borderColor: 'transparent', // No focus effect
    },
  }),
  valueContainer: base => ({
    ...base,
    padding: '0.375rem 0.75rem',
  }),
  placeholder: base => ({
    ...base,
    color: '#6c757d',
  }),
}

const baseStyle = {
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
}
const activeStyle = {
  borderColor: '#2196f3',
}
const acceptStyle = {
  borderColor: '#00e676',
}
const rejectStyle = {
  borderColor: '#ff1744',
}

const sourceOptions = [
  'Hertz',
  'Lica Trade In',
  'FB Marketplace',
  "Buyer's Trade In",
  'Non-Lica Bank Repo',
  'Mobee',
  'LDB Repo',
  'Referral',
  'Orico',
  'Others',
]

const currentYear = new Date().getFullYear()
const years = Array.from(
  { length: currentYear - 1994 },
  (_, i) => currentYear - i,
)
interface Props {
  visible: boolean
  handleClose: () => void
  appraisers: any
  onSubmit: (data: any) => void
}

export function Appraisal(props: Props) {
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({ shouldUnregister: true })

  const purposeWatch = watch('purpose')
  const sourceWatch = watch('source')

  const [loading, setLoading] = React.useState<boolean>(false)
  const [acceptedFiles, setAcceptedFiles] = React.useState<any>([])
  const [files, setFiles] = React.useState<any>([])
  const [makes, setMakes] = React.useState<any>([])
  const [models, setModels] = React.useState<any>([])
  const [manualMakeInput, setManualMakeInput] = React.useState<boolean>(false)
  const [manualModelInput, setManualModelInput] = React.useState<boolean>(false)

  const onDrop = React.useCallback((acceptedFiles: any) => {
    setAcceptedFiles(acceptedFiles)
    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    )
  }, [])

  React.useEffect(() => {
    fetchMakes()
  }, [])

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      handleSelect(value, name)
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const fetchMakes = async () => {
    try {
      const makesList = await axios.get('https://appraisal.carmax.com.ph/makes')
      let options: any = []
      if (makesList?.data) {
        makesList?.data?.map((make: any) => {
          options.push({ value: make, label: make })
        })
        options.push({ value: 'manual_make', label: '[Input vehicle make]' })
      }
      setMakes(options)
    } catch (error) {}
  }

  const handleSelect = async (value: any, name: any) => {
    try {
      if (name === 'make') {
        if (value?.[name]?.value === 'manual_make') {
          setManualMakeInput(true)
          setValue('make', '')
        } else {
          const modelsList = await axios.get(
            'https://appraisal.carmax.com.ph/models?make=' + value?.make?.value,
          )
          let options: any = []
          if (modelsList?.data) {
            modelsList?.data?.map((model: any) => {
              options.push({ value: model, label: model })
            })
            options.push({ value: 'manual_model', label: '[Input make model]' })
          }
          setModels(options)
        }
      }
      if (name === 'model' && value?.[name]?.value === 'manual_model') {
        setManualModelInput(true)
        setValue('model', '')
      }
    } catch (error) {}
  }

  const handleClose = () => {
    reset()
    props.handleClose()
  }

  const onSubmit = async (data: any) => {
    try {
      setLoading(true)
      const params = {
        make: data?.make?.value || data?.make,
        model: data?.model?.value || data?.model,
        year: data?.year,
        transmission: data?.transmission,
        fuel_type: data?.fuel_type,
        mileage: data?.mileage,
        asking_price: data?.asking_price,
      }
      const queryString = Object.keys(params)
        .map(key => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        })
        .join('&')
      const response = await axios.get(
        'https://appraisal.carmax.com.ph/predict?' + queryString,
      )
      if (response?.data) {
        data.estimated_value = response?.data?.predicted_value

        submitAppraisal(data)
      }
    } catch (error) {
      submitAppraisal(data)
    }
  }

  const submitAppraisal = (data: any) => {
    const formData = new FormData()
    Object.keys(data).forEach(key => {
      if (key === 'make' || key === 'model') {
        formData.append(key, data[key]?.value || data[key])
      } else {
        formData.append(key, data[key])
      }
    })
    formData.append('status', 'Canvassing')
    formData.append('origin', 'cms')
    if (acceptedFiles.length) {
      Object.keys(acceptedFiles).forEach(key => {
        formData.append('files[]', acceptedFiles[key])
      })
    }
    if (data.date_appraisal_request) {
      const originalDate = new Date(data.date_appraisal_request)
      originalDate.setDate(originalDate.getDate() + 1)
      formData.append(
        'date_appraisal_request',
        originalDate.toISOString().split('T')[0],
      )
    }

    props.onSubmit(formData)
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
    maxFiles: 3,
  })

  const thumbs = files.map((file: any) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          alt="carmax.png"
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
      </div>
    </div>
  ))

  const style = React.useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept],
  )

  return (
    <Modal show={props.visible} size="lg" onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Add Appraisal</Modal.Title>
        <p onClick={handleClose}>
          <AiFillCloseCircle size={25} />
        </p>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h5 className="mb-3">Vehicle Details</h5>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="date_appraisal_request">
                  Date of appraisal request
                </label>
                <Controller
                  name="date_appraisal_request"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        selected={field.value ? new Date(field.value) : null}
                        dateFormat="yyyy-MM-dd"
                        onChange={date => field.onChange(date)}
                        id="date_appraisal_request"
                        className={`form-control form-control-lg ${
                          errors?.date_appraisal_request ? 'is-invalid' : ''
                        }`}
                        ref={ref => field.ref(ref)}
                      />
                    )
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="purpose">Purpose</label>
                <select
                  id="purpose"
                  className={`form-control form-control-lg  ${
                    errors?.purpose ? 'is-invalid' : ''
                  }`}
                  {...register('purpose', { required: true })}
                >
                  <option value="">--</option>
                  <option value={'inventory'}>Inventory</option>
                  <option value={'sales request'}>Sales Request</option>
                </select>
              </div>
            </div>
            {purposeWatch === 'sales request' && (
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="client">Client</label>
                  <input
                    id="client"
                    type="text"
                    className={`form-control form-control-lg ${
                      errors?.client ? 'is-invalid' : ''
                    }`}
                    {...register('client', { required: true })}
                  />
                </div>
              </div>
            )}
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="address">Location</label>
                <input
                  id="address"
                  type="text"
                  className={`form-control form-control-lg ${
                    errors?.address ? 'is-invalid' : ''
                  }`}
                  {...register('address', { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="year">Year</label>
                <select
                  id="year"
                  className={`form-control form-control-lg  ${
                    errors?.year ? 'is-invalid' : ''
                  }`}
                  {...register('year', { required: true })}
                  onChange={e => handleSelect('year', e.target.value)}
                >
                  <option value="">--</option>
                  {years.map(year => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="make">Make</label>
                {!manualMakeInput ? (
                  <Controller
                    control={control}
                    name="make"
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <Select
                        inputId="make"
                        className={`form-control form-control-lg p-0 ${
                          errors.make ? 'is-invalid' : ''
                        }`}
                        options={makes}
                        onChange={onChange}
                        isSearchable={true}
                        styles={styles}
                      />
                    )}
                  />
                ) : (
                  <input
                    id="make"
                    className={`form-control form-control-lg ${
                      errors?.make ? 'is-invalid' : ''
                    }`}
                    {...register('make', { required: true })}
                  />
                )}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Model</label>
                {!manualModelInput ? (
                  <Controller
                    control={control}
                    name="model"
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <Select
                        inputId="model"
                        className={`form-control form-control-lg p-0 ${
                          errors.model ? 'is-invalid' : ''
                        }`}
                        options={models}
                        onChange={onChange}
                        isSearchable={true}
                        styles={styles}
                      />
                    )}
                  />
                ) : (
                  <input
                    id="model"
                    className={`form-control form-control-lg ${
                      errors?.model ? 'is-invalid' : ''
                    }`}
                    {...register('model', { required: true })}
                  />
                )}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="variant">Variant</label>
                <input
                  id="variant"
                  type="text"
                  className={`form-control form-control-lg ${
                    errors?.variant ? 'is-invalid' : ''
                  }`}
                  {...register('variant', { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="vehicle_type">Vehicle type</label>
                <select
                  id="vehicle_type"
                  {...register('vehicle_type', { required: true })}
                  className={`form-control form-control-lg ${
                    errors?.vehicle_type ? 'is-invalid' : ''
                  }`}
                >
                  <option value="" />
                  <option value="Sedan">Sedan</option>
                  <option value="Compact">Compact</option>
                  <option value="SUV">SUV</option>
                  <option value="Midsize SUV">Midsize SUV</option>
                  <option value="Van">Van</option>
                  <option value="Pick Up">Pick Up</option>
                  <option value="Motorcycle">Motorcycle</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="mileage">Mileage</label>
                <select
                  id="mileage"
                  className={`form-control form-control-lg ${
                    errors?.mileage ? 'is-invalid' : ''
                  }`}
                  {...register('mileage', { required: true })}
                >
                  <option value="">--</option>
                  <option value="0-10,000">0-10,000</option>
                  <option value="10,001-20,000">10,001-20,000</option>
                  <option value="20,001-30,000">20,001-30,000</option>
                  <option value="30,001-40,000">30,001-40,000</option>
                  <option value="40,001-50,000">40,001-50,000</option>
                  <option value="50,001-60,000">50,001-60,000</option>
                  <option value="60,001-70,000">60,001-70,000</option>
                  <option value="70,001-80,000">70,001-80,000</option>
                  <option value="80,001-90,000">80,001-90,000</option>
                  <option value="90,001-100,000">90,001-100,000</option>
                  <option value="100,000"> more than 100,000</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="fuel_type">Fuel type</label>
                <select
                  id="fuel_type"
                  className={`form-control form-control-lg ${
                    errors?.fuel_type ? 'is-invalid' : ''
                  }`}
                  {...register('fuel_type', { required: true })}
                >
                  <option value="" />
                  <option value="Gas">Gas</option>
                  <option value="Diesel">Diesel</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="transmission">Transmission</label>
                <select
                  id="transmission"
                  className={`form-control form-control-lg ${
                    errors?.transmission ? 'is-invalid' : ''
                  }`}
                  {...register('transmission', { required: true })}
                >
                  <option value="" />
                  <option value="Manual">Manual</option>
                  <option value="Automatic">Automatic</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="engine_size">Engine Size</label>
                <input
                  id="engine_size"
                  type="text"
                  className={`form-control form-control-lg ${
                    errors?.engine_size ? 'is-invalid' : ''
                  }`}
                  {...register('engine_size', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="plate_no">Plate #</label>
                <input
                  id="plate_no"
                  type="text"
                  className={`form-control form-control-lg ${
                    errors?.plate_no ? 'is-invalid' : ''
                  }`}
                  {...register('plate_no', {
                    required: true,
                    onChange: e => {
                      const inputValue = e.target.value
                      const transformedValue = inputValue
                        .replace(/[^a-zA-Z0-9]/g, '')
                        .toUpperCase()
                      e.target.value = transformedValue
                      return transformedValue
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="asking_price">Asking price</label>
                <input
                  id="asking_price"
                  type="number"
                  className={`form-control form-control-lg ${
                    errors?.asking_price ? 'is-invalid' : ''
                  }`}
                  {...register('asking_price', { required: false })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="source">Source</label>
                <select
                  id="source"
                  className={`form-control form-control-lg ${
                    errors?.source ? 'is-invalid' : ''
                  }`}
                  {...register('source', { required: false })}
                >
                  <option value="" />
                  {sourceOptions.map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            {sourceWatch === 'Lica Trade In' && (
              <div className="col-lg-3">
                <div className="form-group">
                  <label htmlFor="dealer">Dealer</label>
                  <input
                    id="dealer"
                    type="text"
                    className={`form-control form-control-lg ${
                      errors?.dealer ? 'is-invalid' : ''
                    }`}
                    {...register('dealer', {
                      required: true,
                    })}
                  />
                </div>
              </div>
            )}
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="initial_appraised_value">
                  Initial appraised value
                </label>
                <input
                  id="initial_appraised_value"
                  type="number"
                  className={`form-control form-control-lg ${
                    errors?.initial_appraised_value ? 'is-invalid' : ''
                  }`}
                  {...register('initial_appraised_value', { required: false })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="appraiser_id">Appraiser</label>
                <select
                  id="appraiser_id"
                  className={`form-control form-control-lg ${
                    errors?.appraiser_id ? 'is-invalid' : ''
                  }`}
                  {...register('appraiser_id', { required: true })}
                >
                  <option value="" />
                  {props.appraisers.map((appraiser: any) => (
                    <option key={appraiser.id} value={appraiser.id}>
                      {appraiser.firstname + ' ' + appraiser.lastname}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="remarks">Remarks</label>
                <input
                  id="remarks"
                  type="text"
                  className={`form-control form-control-lg ${
                    errors?.remarks ? 'is-invalid' : ''
                  }`}
                  {...register('remarks', { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="modifications">Modifications</label>
                <input
                  id="modifications"
                  type="text"
                  className={`form-control form-control-lg ${
                    errors?.modifications ? 'is-invalid' : ''
                  }`}
                  {...register('modifications', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="issues">Issues</label>
                <input
                  id="issues"
                  type="text"
                  className={`form-control form-control-lg ${
                    errors?.issues ? 'is-invalid' : ''
                  }`}
                  {...register('issues', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="upload-container">
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p className="text-center mb-0">
                    Drag 'n' drop some files here, or click to select files
                  </p>
                  <small>(Only *.jpeg and *.png images will be accepted)</small>
                </div>
              </div>
              <aside style={thumbsContainer}>{thumbs}</aside>
            </div>
          </div>
          <h5 className="mb-3">Seller Details</h5>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="firstname">Firstname</label>
                <input
                  id="firstname"
                  type="text"
                  className={`form-control form-control-lg ${
                    errors?.firstname ? 'is-invalid' : ''
                  }`}
                  {...register('firstname', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="lastname">Lastname</label>
                <input
                  id="lastname"
                  type="text"
                  className={`form-control form-control-lg ${
                    errors?.lastname ? 'is-invalid' : ''
                  }`}
                  {...register('lastname', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  className={`form-control form-control-lg ${
                    errors?.email ? 'is-invalid' : ''
                  }`}
                  {...register('email', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="phone">Phone</label>
                <input
                  id="phone"
                  type="number"
                  className={`form-control form-control-lg ${
                    errors?.phone ? 'is-invalid' : ''
                  }`}
                  {...register('phone', {
                    required: true,
                    minLength: 11,
                    maxLength: 12,
                  })}
                  onInput={e => {
                    if (e.currentTarget.value.length > 12) {
                      e.currentTarget.value = e.currentTarget.value.slice(0, 12)
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label htmlFor="intention">I am interested to</label>
                <select
                  id="intention"
                  className={`form-control form-control-lg ${
                    errors?.intention ? 'is-invalid' : ''
                  }`}
                  {...register('intention', { required: true })}
                >
                  <option value="" />
                  <option value="buy">Buy</option>
                  <option value="sell">Sell</option>
                  <option value="trade-in">Trade-In</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-2 mb-3">
            <div className="col-lg-12">
              <div className="d-flex justify-content-end">
                <Button
                  type={loading ? 'button' : 'submit'}
                  variant="primary"
                  disabled={loading}
                >
                  {loading ? 'Loading......' : 'Inspection report '}
                  {!loading && <BsChevronRight size={25} />}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
