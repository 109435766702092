/**
 *
 * AppraisalsPage
 *
 */
import React, { memo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
// Components
import { PageLayout } from 'app/components/PageLayout'
import { Appraisal } from 'app/components/Appraisal'
import { AppraisalStatusUpdate } from 'app/components/AppraisalStatusUpdate'
import { AppraisalInfoUpdate } from 'app/components/AppraisalInfoUpdate'
import { VehicleAppraisal } from 'app/components/VehicleAppraisal'
// External
import moment from 'moment'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { AiOutlineCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { MdPictureAsPdf } from 'react-icons/md'
import { toast } from 'react-toastify'
// Utils
import { config } from 'utils/config'
import { getLocalStorage } from 'utils/tokenStorage'
// Utils
import { Auth } from 'utils/auth'

interface Props {}

const dateFormatter = (cell, row) => {
  if (!cell) {
    return ''
  }
  return moment(cell).format('MM DD YYYY, h:mm:ss a')
}

export const AppraisalsPage = memo((props: Props) => {
  const { register, handleSubmit } = useForm()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [appraisals, setAppraisals] = React.useState<[]>([])
  const [appraisers, setAppraisers] = React.useState<[]>([])
  const [suppliers, setSuppliers] = React.useState<[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [page, setPage] = React.useState<number>(0)
  const [totalSize, setTotalSize] = React.useState(1)
  const [selectedRow, setSelectedRow] = React.useState<{ [key: string]: any }>(
    {},
  )
  const [openAppraisal, setOpenAppraisal] = React.useState<boolean>(false)
  const [openStatusUpdate, setOpenStatusUpdate] = React.useState<boolean>(false)
  const [openInfoUpdate, setOpenInfoUpdate] = React.useState<boolean>(false)
  const [prediction, setPrediction] = React.useState<any | null>(null)

  React.useEffect(() => {
    fetchAppraisals(location.search)
    fetchAppraisers()
    fetchSuppliers()
  }, [])

  React.useEffect(() => {
    fetchAppraisals(location.search)
  }, [location])

  const fetchAppraisals = async (params: String) => {
    try {
      setLoading(true)
      const { data } = await config.apiClient.get(
        `/appraisals/requests${params}`,
        {
          headers: { Authorization: 'Bearer ' + getLocalStorage().token },
        },
      )

      setAppraisals(data.data.data)
      setTotalSize(data.data.total)
      setPage(data.data.current_page)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const fetchAppraisers = async () => {
    const { data } = await config.apiClient.get('/users?role=acquisition', {
      headers: { Authorization: 'Bearer ' + getLocalStorage().token },
    })
    setAppraisers(data.data.data)
  }

  const fetchSuppliers = async () => {
    try {
      const { data } = await config.apiClient.get(`/suppliers`, {
        headers: { Authorization: 'Bearer ' + getLocalStorage().token },
      })
      setSuppliers(data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleTableChange = (type: String, { page, sizePerPage }) => {
    if (type === 'pagination') {
      searchParams.set('page', page)
      setSearchParams(searchParams)
    }
  }

  const handleDelete = async (row: any) => {
    if (window.confirm('Are you sure you want to delete this data?')) {
      try {
        setLoading(true)
        await config.apiClient.delete('/appraisals/requests/' + row.id, {
          headers: { Authorization: 'Bearer ' + getLocalStorage().token },
        })
        setLoading(false)
        toast.success('Successfully deleted!')
        fetchAppraisals('')
      } catch (error) {
        setLoading(false)
        toast.error('Something went wrong')
      }
    }
  }

  const handleUpdate = async (payload: any) => {
    try {
      handleClose()
      setLoading(true)
      await config.apiClient.put(
        '/appraisals/requests/' + payload.id,
        payload,
        {
          headers: { Authorization: 'Bearer ' + getLocalStorage().token },
        },
      )

      setLoading(false)
      toast.success('Successfully updated!')
      fetchAppraisals('')
    } catch (error) {
      setLoading(false)
      toast.error('Something went wrong')
    }
  }

  const handleAddNew = () => {
    setOpenAppraisal(true)
  }

  const handleStatusUpdate = (row: any) => {
    setSelectedRow(row)
    setOpenStatusUpdate(true)
  }

  const handleInfoUpdate = (row: any) => {
    setSelectedRow(row)
    setOpenInfoUpdate(true)
  }

  const handleClose = () => {
    setOpenAppraisal(false)
    setOpenStatusUpdate(false)
    setOpenInfoUpdate(false)
    setSelectedRow({})
  }

  const onSubmit = (data: any) => {
    searchParams.set('search', data?.search)
    setSearchParams(searchParams)
  }

  const handleSelectStatus = (event: any) => {
    if (event.target.value) {
      searchParams.set('status', event.target.value)
      setSearchParams(searchParams)
    } else {
      searchParams.delete('status')
      setSearchParams(searchParams)
    }
  }

  const handleSelectOrigin = (event: any) => {
    if (event.target.value) {
      searchParams.set('origin', event.target.value)
      setSearchParams(searchParams)
    } else {
      searchParams.delete('origin')
      setSearchParams(searchParams)
    }
  }

  const onSubmitAppraisal = async (payload: any) => {
    try {
      handleClose()
      setLoading(true)
      const { data } = await config.apiClient.post(
        '/appraisals/requests',
        payload,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )
      toast.success('Successfully submitted!')
      navigate('/appraisals/inspection/' + data?.data?.id)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const sizePerPage = 15
  const columns = [
    {
      dataField: 'df1',
      isDummyField: true,
      text: 'Actions',
      headerClasses: 'id-custom-width',
      formatter: (_cellContent, row) => {
        const role = Auth.role()
        const disabledStatus = ['Not Recommended', 'Declined', 'Acquired']
        const enabledStatusRole = ['For Payment', 'P.O Issued']

        let disabledBtn = false

        if (role.includes('inventory') && row.origin === 'appraisal') {
          if (enabledStatusRole.includes(row.status)) {
            disabledBtn = false
          } else {
            disabledBtn = true
          }
        } else {
          if (disabledStatus.includes(row.status)) {
            disabledBtn = true
          } else {
            disabledBtn = false
          }
        }

        let appraisalEnable = true
        if (row.origin === 'appraisal') {
          if (row.status === 'P.O Issued' || row.status === 'Acquired') {
            appraisalEnable = false
          } else {
            appraisalEnable = true
          }
        } else {
          appraisalEnable = false
        }

        const appraisalEnableFillOut =
          (row.origin === 'appraisal' && row.with_inspection) ||
          row.origin === 'cms'

        return (
          <>
            <button
              type="button"
              className="btn btn-success"
              onClick={() => handleStatusUpdate(row)}
              disabled={disabledBtn}
            >
              Update Status
            </button>
            <button
              type="button"
              className="btn btn-info"
              disabled={appraisalEnableFillOut}
              onClick={() => handleInfoUpdate(row)}
            >
              Fill out details
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handleDelete(row)}
            >
              Delete
            </button>
            {row.with_inspection && (
              <button
                type="button"
                className="btn btn-default"
                onClick={() => navigate('/appraisals/inspection/' + row.id)}
                disabled={appraisalEnable}
              >
                <AiOutlineCheckCircle size="0.8rem" color="green" /> Inspection
                report
              </button>
            )}
            {!row.with_inspection && (
              <button
                type="button"
                className="btn btn-warning"
                onClick={() => navigate('/appraisals/inspection/' + row.id)}
                disabled={appraisalEnable}
              >
                <AiFillCloseCircle size="0.8rem" color="red" /> Inspection
                report
              </button>
            )}
          </>
        )
      },
    },
    {
      dataField: 'status',
      text: 'Status',
    },
    {
      dataField: 'plate_no',
      text: 'Plate #',
    },
    {
      dataField: 'make',
      text: 'Vehicle',
      formatter: (cellContent: any, row: any) => {
        return <p>{`${row.make} ${row.model} ${row.year}`}</p>
      },
    },
    {
      dataField: 'origin',
      text: 'Origin',
    },
    {
      dataField: 'estimated_value',
      text: 'Estimated value',
      formatter: (cellContent: any, row: any) => {
        if (!row.estimated_value) return
        return <p>{parseFloat(row.estimated_value).toLocaleString()}</p>
      },
    },
    {
      dataField: 'min_value',
      text: 'Estimated range value',
      formatter: (cellContent: any, row: any) => {
        if (!row.min_value) return
        return (
          <p>{`${parseFloat(row.min_value).toLocaleString()} - ${parseFloat(
            row.max_value,
          ).toLocaleString()}`}</p>
        )
      },
    },
    {
      dataField: 'initial_appraised_value',
      text: 'Initial appraised value',
      formatter: (cellContent, row) => {
        if (!row.initial_appraised_value) return
        return <p>{parseFloat(row.initial_appraised_value).toLocaleString()}</p>
      },
    },
    {
      dataField: 'lastname',
      text: 'Client',
      formatter: (cellContent, row) => {
        return <p>{`${row.firstname} ${row.lastname}`}</p>
      },
    },
    {
      dataField: 'phone',
      text: 'Contact#',
    },
    {
      dataField: 'created_at',
      text: 'Created',
      formatter: dateFormatter,
    },
  ]

  const expandRow = {
    showExpandColumn: true,
    renderer: (row: any) => {
      const photos: any = []
      row?.images.map((item: any) => {
        photos.push({
          id: item.id,
          src: item.url,
          width: 2,
          height: 3,
        })
      })

      return (
        <div className="row">
          <div className="col-4">
            <div className="list-group">
              <a
                href="#"
                className="list-group-item list-group-item-action flex-column align-items-start active"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">Client</h5>
                </div>
                <p className="mb-0">{row.firstname + ' ' + row.lastname}</p>
                <p className="mb-0">{row.email}</p>
                <p className="mb-0">{row.phone}</p>
                <p className="mb-0">{row.intention}</p>
              </a>
              <a
                href="#"
                className="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">Vehicle</h5>
                </div>
                <p className="mb-0">
                  Date of appraisal request: {row.date_appraisal_request}
                </p>
                <p className="mb-0">Make: {row.make}</p>
                <p className="mb-0">Model: {row.model}</p>
                <p className="mb-0">Year: {row.year}</p>
                <p className="mb-0">Variant: {row.variant}</p>
                <p className="mb-0">Mileage: {row.mileage}</p>
                <p className="mb-0">Fuel type: {row.fuel_type}</p>
                <p className="mb-0">Transmission: {row.transmission}</p>
                <p className="mb-0">Plate no: {row.plate_no}</p>
                <p className="mb-0">Purpose: {row.purpose}</p>
                <p className="mb-0">Client: {row.client}</p>
                <p className="mb-0">Source: {row.source}</p>
                <p className="mb-0">Dealer: {row.dealer}</p>
                <p className="mb-0">Issues: {row.issues}</p>
                <p className="mb-0">
                  Asking Price:{' '}
                  {parseFloat(
                    row.asking_price ? row.asking_price : 0,
                  ).toLocaleString()}
                </p>
                <p className="mb-0">Vehicle Type: {row.vehicle_type}</p>
                <p className="mb-0">Engine Size: {row.engine_size}</p>
                <p className="mb-0">Modifications: {row.modifications}</p>
                <p className="mb-0">Location: {row.address}</p>
                <p className="mb-0">Remarks: {row.remarks}</p>
              </a>
              <a
                href="#"
                className="list-group-item list-group-item-action flex-column align-items-start"
              >
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="mb-1">Appraisal</h5>
                </div>
                <p className="mb-0">
                  Initial: {row.initial_appraised_value || 0}
                </p>
                <p className="mb-0">Original: {row.estimated_value}</p>
                <p className="mb-0">Estimated(min): {row.min_value}</p>
                <p className="mb-0">Estimated(max): {row.max_value}</p>
                <p className="mb-0">Source: {row.source}</p>
                <p className="mb-0">Origin: {row.origin || 'web'}</p>
                <p className="mb-0">
                  Appraiser:{' '}
                  {row?.appraiser
                    ? row.appraiser.firstname + ' ' + row.appraiser.lastname
                    : 'n/a'}
                </p>
              </a>
              {row.with_inspection && row?.inspection?.id && (
                <a
                  href={`https://cm-storage.sgp1.digitaloceanspaces.com/inspections/report-${row?.inspection?.id}.pdf`}
                  className="list-group-item list-group-item-action flex-column align-items-start"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">Inspection report</h5>
                  </div>
                  <p className="mb-0">
                    PDF:{' '}
                    <a
                      href={`https://cm-storage.sgp1.digitaloceanspaces.com/inspections/report-${row?.inspection?.id}.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MdPictureAsPdf size={30} color="#FFC770" />
                    </a>
                  </p>
                </a>
              )}
              {row?.purchase_order && row?.purchase_order?.id && (
                <a
                  href={`https://cm-storage.sgp1.digitaloceanspaces.com/receipts/po-${row?.purchase_order?.id}.pdf`}
                  className="list-group-item list-group-item-action flex-column align-items-start"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">Purchase order</h5>
                  </div>
                  <p className="mb-0">
                    PDF:{' '}
                    <a
                      href={`https://cm-storage.sgp1.digitaloceanspaces.com/receipts/po-${row?.purchase_order?.id}.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MdPictureAsPdf size={30} color="#FFC770" />
                    </a>
                  </p>
                </a>
              )}
              {row.utm_medium && (
                <a
                  href="#"
                  className="list-group-item list-group-item-action flex-column align-items-start"
                >
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-1">Tracking/UTM</h5>
                  </div>
                  <p className="mb-0">{row.utm_source}</p>
                  <p className="mb-0">{row.utm_medium}</p>
                </a>
              )}
            </div>
          </div>
          <div className="col-8">
            <h3>Photo/s</h3>
            {photos.length > 0 ? (
              <div className="row">
                {photos.map((photo: any) => (
                  <div key={photo.id} className="col-4">
                    <img
                      src={photo.src}
                      className="img-thumbnail"
                      style={{ width: '100%', height: '100%', borderRadius: 0 }}
                    />
                  </div>
                ))}
              </div>
            ) : (
              'N/A'
            )}
          </div>
        </div>
      )
    },
  }

  return (
    <PageLayout
      pageTitle="Appraisals requests"
      loading={loading}
      icon="mdi-cash-check"
    >
      <Appraisal
        visible={openAppraisal}
        handleClose={handleClose}
        appraisers={appraisers}
        onSubmit={onSubmitAppraisal}
      />
      <AppraisalStatusUpdate
        visible={openStatusUpdate}
        appraisal={selectedRow}
        handleClose={handleClose}
        handleSubmit={handleUpdate}
        role={Auth.role()}
        suppliers={suppliers}
      />
      <AppraisalInfoUpdate
        visible={openInfoUpdate}
        appraisal={selectedRow}
        handleClose={handleClose}
        appraisers={appraisers}
        onSubmit={handleUpdate}
      />
      <VehicleAppraisal
        visible={Boolean(prediction)}
        prediction={prediction}
        handleClose={() => setPrediction(false)}
      />
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-md-3">
                  <form
                    className="d-flex align-items-center mb-3"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control bg-transparent border-1 form-control-sm"
                        placeholder="Search appraisals"
                        {...register('search', { required: true })}
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-sm btn-primary"
                          type="submit"
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-3">
                  <select
                    className="form-control form-control-sm"
                    onChange={handleSelectStatus}
                  >
                    <option value="">----</option>
                    <option value="New">New</option>
                    <option value="Canvassing">Canvassing</option>
                    <option value="Inspected">Inspected</option>
                    <option value="For Payment">For Payment</option>
                    <option value="Not Recommended">Not Recommended</option>
                    <option value="Under Negotiation">Under Negotiation</option>
                    <option value="Under Consideration">
                      Under Consideration
                    </option>
                    <option value="For Approval">For Approval</option>
                    <option value="Approved">Approved</option>
                    <option value="Declined">Declined</option>
                    <option value="Owner Accepted">Owner Accepted</option>
                    <option value="Owner Rejected">Owner Rejected</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="P.O Issued">P.O Issued</option>
                    <option value="Void">Void</option>
                    <option value="Acquired">Acquired</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <select
                    className="form-control form-control-sm"
                    onChange={handleSelectOrigin}
                  >
                    <option value="">----</option>
                    <option value="cms">CMS</option>
                    <option value="appraisal">Appraisal</option>
                    <option value="web">Web</option>
                  </select>
                </div>
                <div className="col-md-3">
                  <button
                    className="btn btn-sm btn-primary"
                    type="submit"
                    onClick={handleAddNew}
                    style={{ padding: '12.5px' }}
                  >
                    Add appraisal
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <BootstrapTable
                  remote
                  keyField="id"
                  data={appraisals}
                  columns={columns}
                  pagination={paginationFactory({
                    page,
                    sizePerPage,
                    totalSize,
                  })}
                  onTableChange={handleTableChange}
                  expandRow={expandRow}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
})
