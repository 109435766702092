/**
 *
 * AppraisalInfoUpdate
 *
 */
import * as React from 'react'
// React bootstrap
import { Modal, Button } from 'react-bootstrap'
// External
import { useForm } from 'react-hook-form'
import { AiFillCloseCircle } from 'react-icons/ai'
import { BsChevronRight } from 'react-icons/bs'
import axios from 'axios'

interface Props {
  visible: boolean
  appraisal: any
  handleClose: () => void
  appraisers: any
  onSubmit: (data) => void
}

export function AppraisalInfoUpdate(props: Props) {
  const { register, handleSubmit, setValue } = useForm()

  const [form, setForm] = React.useState<any>(null)
  const [loading, setLoading] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (props.appraisal && Object.keys(props.appraisal).length > 0) {
      setForm(props.appraisal)
      Object.keys(props.appraisal).forEach(function (key) {
        setValue(key, props.appraisal[key])
      })
    }
  }, [props.appraisal])

  const handleClose = () => {
    props.handleClose()
  }

  const onSubmit = async (data: any) => {
    // get predicted appraised value
    try {
      setLoading(true)
      const params = {
        make: form?.make,
        model: form?.model,
        year: form?.year,
        transmission: data?.transmission,
        fuel_type: data?.fuel_type,
        mileage: data?.mileage,
        asking_price: data?.asking_price,
      }
      const queryString = Object.keys(params)
        .map(key => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        })
        .join('&')
      const response = await axios.get(
        'https://appraisal.carmax.com.ph/predict?' + queryString,
      )

      if (response?.data) {
        data.estimated_value = response?.data?.predicted_value
        submitAppraisal({ ...form, ...data })
      }
    } catch (error) {
      submitAppraisal({ ...form, ...data })
    }
  }

  const submitAppraisal = (data: any) => {
    props.onSubmit(data)
    setLoading(false)
  }

  return (
    <Modal show={props.visible} size="lg">
      <Modal.Header>
        <Modal.Title>Edit appraisal</Modal.Title>
        <p onClick={handleClose}>
          <AiFillCloseCircle size={25} />
        </p>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>Year</label>
                <input
                  className="form-control form-control-lg"
                  {...register('year', { required: true })}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Make</label>
                <input
                  className="form-control form-control-lg"
                  {...register('make', { required: true })}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Model</label>
                <input
                  className="form-control form-control-lg"
                  {...register('model', { required: true })}
                  readOnly={true}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Variant</label>
                <input
                  className="form-control form-control-lg"
                  {...register('variant', { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>Vehicle type *</label>
                <select
                  className="form-control form-control-lg"
                  {...register('vehicle_type', { required: true })}
                >
                  <option value="" />
                  <option value="Sedan">Sedan</option>
                  <option value="Compact">Compact</option>
                  <option value="SUV">SUV</option>
                  <option value="Midsize SUV">Midsize SUV</option>
                  <option value="Van">Van</option>
                  <option value="Pick Up">Pick Up</option>
                  <option value="Motorcycle">Motorcycle</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Mileage</label>
                <select
                  className="form-control form-control-lg"
                  {...register('mileage', { required: true })}
                >
                  <option value=""></option>
                  <option value="0-10,000">0-10,000</option>
                  <option value="10,001-20,000">10,001-20,000</option>
                  <option value="20,001-30,000">20,001-30,000</option>
                  <option value="30,001-40,000">30,001-40,000</option>
                  <option value="40,001-50,000">40,001-50,000</option>
                  <option value="50,001-60,000">50,001-60,000</option>
                  <option value="60,001-70,000">60,001-70,000</option>
                  <option value="70,001-80,000">70,001-80,000</option>
                  <option value="80,001-90,000">80,001-90,000</option>
                  <option value="90,001-100,000">90,001-100,000</option>
                  <option value="100,000"> more than 100,000</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Fuel type</label>
                <select
                  className="form-control form-control-lg"
                  {...register('fuel_type', { required: true })}
                >
                  <option value="" />
                  <option value="Gas">Gas</option>
                  <option value="Diesel">Diesel</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Transmission</label>
                <select
                  className="form-control form-control-lg"
                  {...register('transmission', { required: true })}
                >
                  <option value="" />
                  <option value="Manual">Manual</option>
                  <option value="Automatic">Automatic</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>Engine Size</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  {...register('engine_size', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Plate #</label>
                <input
                  className="form-control form-control-lg"
                  {...register('plate_no', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Asking price</label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  {...register('asking_price', { required: false })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Source</label>
                <input
                  className="form-control form-control-lg"
                  {...register('source', { required: false })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>Inital appraised value</label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  {...register('initial_appraised_value', { required: false })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Appraiser</label>
                <select
                  className="form-control form-control-lg"
                  {...register('appraiser_id', { required: true })}
                >
                  <option value="" />
                  {props.appraisers.map((appraiser: any) => (
                    <option key={appraiser.id} value={appraiser.id}>
                      {appraiser.firstname + ' ' + appraiser.lastname}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Remarks</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  {...register('remarks', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Modifications</label>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  {...register('modifications', { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label>Issues</label>
                <input
                  className="form-control form-control-lg"
                  {...register('issues', { required: true })}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>Firstname</label>
                <input
                  className="form-control form-control-lg"
                  {...register('firstname', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Lastname</label>
                <input
                  className="form-control form-control-lg"
                  {...register('lastname', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control form-control-lg"
                  {...register('email', { required: true })}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group">
                <label>Phone</label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  {...register('phone', {
                    required: true,
                    minLength: 11,
                    maxLength: 12,
                  })}
                  onInput={e => {
                    if (e.currentTarget.value.length > 12) {
                      e.currentTarget.value = e.currentTarget.value.slice(0, 12)
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3">
              <div className="form-group">
                <label>I am interested to</label>
                <select
                  className="form-control form-control-lg"
                  {...register('intention', { required: true })}
                >
                  <option value="" />
                  <option value="buy">Buy</option>
                  <option value="sell">Sell</option>
                  <option value="trade-in">Trade-In</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row mt-2 mb-3">
            <div className="col-lg-12">
              <div className="d-flex justify-content-end">
                {loading ? (
                  <Button type="button" variant="primary" disabled={loading}>
                    Loading......
                  </Button>
                ) : (
                  <Button type="submit" variant="primary" disabled={loading}>
                    Inspection report <BsChevronRight size={25} />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
